<template>
  <gec-dragger
    v-if="showAvatarStudent && !hideAvatar"
    id="avatar-dragger"
    :width="120"
    :height="120"
  >
    <img
      v-if="currentAvatar"
      :src="require(`@/assets/images/interactive-avatars${currentAvatar}`)"
      draggable="false"
      class="w-100 h-100"
    >
  </gec-dragger>
</template>

<script>
import {
  IDLE,
  SLEEP,
  RIGHT,
  WRONG,
  STATUS_WRONG,
  STATUS_IDLE,
  STATUS_RIGHT,
  STATUS_SLEEP,
} from '@/const/avatar'
import { isVisible } from '@/utils/visibilitySettings'
import GecDragger from '@/layouts/components/GECDragger.vue'

export default {
  components: {
    GecDragger,
  },

  computed: {
    activeImageIndex() {
      return this.$store.state.appConfig.selectedCharacter
    },
    studentAttention() {
      return this.$store.getters['studentInteractions/currentStudentAttention']
    },
    currentStatus() {
      return this.$store.state.appConfig.currentStatus
    },
    showAvatarStudent() {
      return this.$store.state.appConfig.showAvatarStudent
    },
    // showAvatar() {
    //   return this.$store.state.appConfig.showAvatar
    // },
    hideAvatar() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('avatar', schoolSetting, distSetting)
    },
    currentAvatar: {
      get() {
        if (this.currentStatus === STATUS_WRONG) return WRONG[this.activeImageIndex]
        if (this.currentStatus === STATUS_RIGHT) return RIGHT[this.activeImageIndex]
        if (this.currentStatus === STATUS_SLEEP) return SLEEP[this.activeImageIndex]
        if (this.currentStatus === STATUS_IDLE) return IDLE[this.activeImageIndex]
        return null
      },
    },
  },
}
</script>
<style scoped>
@media only screen and (max-width: 1000px) {
  #interactive-avatar {
    width: 100px;
    height: 100px;
  }
}
</style>
